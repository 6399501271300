<template>
  <h3 class="title">
    <span>{{ name }}</span> {{ surname }}
  </h3>
</template>

<script>
export default {
  computed: {
    name() {
      return process.env.VUE_APP_PERSON_NAME;
    },
    surname() {
      return process.env.VUE_APP_PERSON_SURNAME;
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  margin-left: -20px;
  margin-right: -20px;
  font-size: 1.8125rem;
  font-family: $font-family-merriweather;
  margin-bottom: 0.875rem;
  position: relative;
  word-wrap: break-word;
  font-weight: 700;
  line-height: 1.3;
  letter-spacing: 0.5px;
  color: $blue;

  span {
    color: $strong-grey;
    font-weight: 300;
  }
}
</style>
