<template>
  <div id="app" class="global-font">
    <layout />
  </div>
</template>

<script>
import Layout from "@/components/Layout.vue";

export default {
  name: "App",
  components: {
    Layout,
  },
};
</script>

<style lang="scss">
body {
  margin: 0;
  padding: 0;
  background-color: $light-grey;
  color: $strong-grey;
}
</style>
