<template>
  <div class="profession">{{ profession }}</div>
</template>

<script>
export default {
  computed: {
    profession() {
      return process.env.VUE_APP_PERSON_PROFESSION;
    },
  },
};
</script>

<style lang="scss" scoped>
.profession {
  background-color: #e9edf0;
  font-size: 0.9375rem;
  font-weight: 300;
  border-radius: 10rem;
  padding: 0.5rem 1rem;
}
</style>
