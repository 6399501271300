<template>
  <div class="info">
    <i :class="icon"></i>
    {{ text }}
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  display: flex;
  align-items: center;
  margin-bottom: 0.6875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  i {
    margin-right: 0.875rem;
    font-size: 18px;
  }
}
</style>
