import Vue from "vue";
import App from "./App.vue";

//Element ui
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import locale from "element-ui/lib/locale/lang/en";

Vue.use(ElementUI, { locale });
Vue.use(ElementUI);

//Fontawesome
import { library } from "@fortawesome/fontawesome-svg-core";
import { faLinkedin, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(faLinkedin);
library.add(faInstagram);

Vue.component("font-awesome-icon", FontAwesomeIcon);

//Router
import router from "@/router";

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
