<template>
  <el-button
    type="default"
    class="btn btn-download"
    icon="el-icon-download"
    round
    @click="download"
    >Descargar CV</el-button
  >
</template>

<script>
export default {
  methods: {
    download() {
      let link = document.createElement("a");
      link.href = "/files/ignacio_maresca.pdf";
      link.download = "Ignacio Maresca.pdf";
      link.dispatchEvent(new MouseEvent("click"));
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-download {
  width: 100%;
  margin-top: 1rem;
  height: 60px;
}
</style>
