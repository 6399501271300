import { render, staticRenderFns } from "./Presentation.vue?vue&type=template&id=67975894&scoped=true&"
import script from "./Presentation.vue?vue&type=script&lang=js&"
export * from "./Presentation.vue?vue&type=script&lang=js&"
import style0 from "./Presentation.vue?vue&type=style&index=0&id=67975894&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "67975894",
  null
  
)

export default component.exports