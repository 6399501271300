<template>
  <div class="presentation">
    <div class="name">
      <image-cv />
      <name />
      <profession />
      <social />
    </div>
    <div class="details">
      <div class="detail-texts">
        <info icon="el-icon-date" :text="birthdate" />
        <info icon="el-icon-location-outline" :text="location" />
        <info icon="el-icon-message" :text="email" />
        <!--info icon="el-icon-mobile-phone" :text="phone" /!-->
      </div>
      <btn-download />
    </div>
  </div>
</template>

<script>
import ImageCv from "@/components/presentation/name/Image";
import Name from "@/components/presentation/name/Title";
import Profession from "@/components/presentation/name/Profession";
import Social from "@/components/presentation/name/Social";
import Info from "@/components/presentation/details/Info";
import BtnDownload from "@/components/presentation/details/BtnDownload";

export default {
  name: "Andrea",
  components: {
    ImageCv,
    Name,
    Profession,
    Social,
    Info,
    BtnDownload,
  },
  computed: {
    birthdate() {
      return process.env.VUE_APP_PERSON_BIRTHDATE;
    },
    location() {
      return process.env.VUE_APP_PERSON_LOCATION;
    },
    email() {
      return process.env.VUE_APP_PERSON_EMAIL;
    },
    /*phone() {
      return process.env.VUE_APP_PERSON_PHONE;
    },*/
  },
};
</script>

<style lang="scss" scoped>
.presentation {
  position: sticky;
  align-self: flex-start;
  top: 120px;
  width: 26%;
  padding: 0 2%;

  .name {
    display: flex;
    flex-flow: column;
    align-items: center;
    border-top-left-radius: 7%;
    border-top-right-radius: 7%;
    background-color: white;
    padding: 0 5%;
  }

  .details {
    border-bottom-left-radius: 10%;
    border-bottom-right-radius: 10%;
    background-color: $grey;
    padding: 2rem 15% 2rem 15%;
  }
}

@media (max-width: 1100px) {
  .presentation {
    width: 100%;
    margin-top: 10em;
    position: relative;
    align-self: auto;
    top: 0;

    .detail-texts {
      display: none;
    }
  }
}
</style>
