<template>
  <div
    :class="active === true ? 'nav-item active' : 'nav-item'"
    @click="$emit('click', id)"
  >
    {{ name }}
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    active: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-item {
  color: rgba(255, 255, 255, 0.8);
  cursor: pointer;
  margin: 0 20px;
}

.active {
  border-bottom: 1px solid white;
  color: white;
}
</style>
